import {
  Component,
  EventEmitter,
  forwardRef,
  input,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {
  ActionTypeFrameDimensionEvent,
  ConfigurableObject,
  ConnectedStationInfo,
  CustomIdSettings,
  DeleteImageTemp,
  FiltersDataModel,
  GenericWidget,
  GridsterItemWidget,
  ImageMode,
  ImageWidgetData,
  InputFrameUpdatedEvent,
  Power,
  Question,
  QuestionFieldType,
  SortingWidgetData,
  StandardStringJSON,
  StationBucketQuestion,
  StationWidgetData,
  TextWidgetAlignmentType,
  TextWidgetInformation,
  WidgetType,
} from 'src/models';
import { StationService } from 'src/app/core/station.service';
import {
  DashboardWidgets,
  InputFrame,
  JsonValidator,
  LibraryHelper,
  MobileBrowserChecker,
  TermsGeneric,
  WidgetFiltersHelper,
} from 'src/helpers';
import { v4 as uuidv4 } from 'uuid';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import {
  catchError,
  first,
  firstValueFrom,
  forkJoin,
  fromEvent,
  of,
  Subject,
  takeUntil,
} from 'rxjs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { BoardService } from 'src/app/board/board.service';
import { StationComponent } from '../station/station.component';
import {
  GridsterConfig,
  GridsterItemComponent,
  GridsterItemComponentInterface,
  GridsterModule,
  GridsterPush,
} from 'angular-gridster2';
import { SidenavDrawerService } from 'src/app/core/sidenav-drawer.service';
import { PopupService } from 'src/app/core/popup.service';
import { ErrorService } from 'src/app/core/error.service';
import { ContainerService } from 'src/app/core/container.service';
import _ from 'lodash';
import { PowerService } from 'src/app/core/power.service';
import { ConfirmRemoveQuestionFrameModalComponent } from 'src/app/station/confirm-remove-question-frame-modal/confirm-remove-question-frame-modal';
import {
  DISPLAY_GRID,
  FIXED_ROW_HEIGHT,
  GRID_TYPE,
  MARGIN,
  MAX_COLS,
  MAX_ITEM_ROWS,
  MAX_ROWS,
  MIN_COLS,
  MIN_ROW_FRAME,
} from 'src/app/station/station-grid-const';
import { UserService } from 'src/app/core/user.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { BodyTextWidgetComponent } from 'src/app/shared/station-container-widgets/body-text-widget/body-text-widget.component';
import { HeadlineWidgetComponent } from 'src/app/shared/station-container-widgets/headline-widget/headline-widget.component';
import { InputFrameWidgetComponent } from 'src/app/shared/station-container-widgets/input-frame-widget/input-frame-widget.component';
import { TitleWidgetComponent } from 'src/app/shared/station-container-widgets/title-widget/title-widget.component';
import { ToolbarImageWidgetComponent } from 'src/app/shared/station-container-widgets/toolbar-image-widget/toolbar-image-widget.component';
import { ContainerPreBuiltWidgetComponent } from 'src/app/shared/widget-dashboard/container-pre-built-widget/container-pre-built-widget.component';
import { ContainerWidgetComponent } from 'src/app/shared/widget-dashboard/container-widget/container-widget.component';
import { GroupContainerWidgetComponent } from 'src/app/shared/widget-dashboard/group-container-widget/group-container-widget.component';
import { GroupSearchWidgetComponent } from 'src/app/shared/widget-dashboard/group-search-widget/group-search-widget.component';
import { GroupTrafficWidgetComponent } from 'src/app/shared/widget-dashboard/group-traffic-widget/group-traffic-widget.component';
import { RelationshipWidgetComponent } from 'src/app/shared/widget-dashboard/relationship-widget/relationship-widget.component';
import { StationPreBuiltWidgetComponent } from 'src/app/shared/widget-dashboard/station-pre-built-widget/station-pre-built-widget.component';
import { StationWidgetComponent } from 'src/app/shared/widget-dashboard/station-widget/station-widget.component';
/**
 * Component for the list of fields on the station grid.
 */
@Component({
  selector: 'app-station-grid[stationRithmId]',
  templateUrl: './station-grid.component.html',
  styleUrls: ['./station-grid.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    GridsterModule,
    MatTooltipModule,
    MatDialogModule,
    DragDropModule,
    ScrollingModule,
    HeadlineWidgetComponent,
    TitleWidgetComponent,
    BodyTextWidgetComponent,
    GroupSearchWidgetComponent,
    GroupTrafficWidgetComponent,
    StationPreBuiltWidgetComponent,
    ContainerPreBuiltWidgetComponent,
    GroupContainerWidgetComponent,
    InputFrameWidgetComponent,
    ToolbarImageWidgetComponent,
    RelationshipWidgetComponent,
    StationWidgetComponent,
    ContainerWidgetComponent,
    LoadingIndicatorComponent,
  ],
  providers: [
    InputFrame,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StationGridComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => StationGridComponent),
      multi: true,
    },
  ],
})
export class StationGridComponent
  implements OnInit, ControlValueAccessor, Validator, OnDestroy
{
  /** Indicate item of Gridster to modify. */
  @ViewChild(GridsterItemComponent, { static: false })
  gridItem!: GridsterItemComponent;

  /** List of all text widget types. */
  readonly textWidgetTypes = [
    WidgetType.BodyWidget,
    WidgetType.TitleWidget,
    WidgetType.HeadlineWidget,
  ];

  /** List of all selectable question types. */
  readonly questionTypeSelectable = [
    QuestionFieldType.CheckList,
    QuestionFieldType.Select,
    QuestionFieldType.MultiSelect,
    QuestionFieldType.RadioList,
  ];

  /** Check-list and Radio-list question type. */
  readonly checkRadioListQuestionType = [
    QuestionFieldType.CheckList,
    QuestionFieldType.RadioList,
  ];

  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Relationship widget flag. */
  @Input({ required: true }) relationshipWidgetFlag = false;

  /** The station fields in the grid area. */
  @Input() fields!: Question[];

  /** Station Widgets array. */
  @Input() inputFrameWidgetItems: GridsterItemWidget[] = [];

  /** The RithmId of the Station. */
  @Input() stationRithmId = '';

  /** The context of what is open in the drawer. */
  @Input() drawerContext = 'comments';

  /** Whether the request to get the station info is currently underway. */
  @Input() stationLoading = false;

  /** Overlay for the gridster when loading an image in the widget. */
  @Input() stationOverlay = false;

  /* The selected index of the container left section toggle button. */
  @Input() selectedInnerLeftIndex = '0';

  /** The list of stations that follow this station. */
  @Input() forwardStations: ConnectedStationInfo[] = [];

  /** The list of all the input frames in the grid. */
  @Input() inputFrameList: string[] = [];

  /** Widget in frames update  flag. */
  @Input({ required: true }) showWidgetFramesUpdate = false;

  /** Feature flag for show widget header and subheader. */
  @Input({ required: true }) headerFeatureFlag = false;

  /** Feature flag for the order of operations coming from the parent. */
  @Input({ required: true }) orderOfOperations = false;

  /** Feature flag for self assign. */
  @Input({ required: true }) showSelfAssign = false;

  /** Feature flag container station overlay. */
  @Input({ required: true }) containerStationOverlayFeature = false;

  /** Feature flag widget column update. */
  @Input({ required: true }) widgetColumnFeatureFlag = false;

  /** Feature Flag for sorting widget. */
  @Input({ required: true }) multiSortingFeatureFlag = false;

  /** Feature flag for station widgets updates. */
  @Input({ required: true }) stationWidgetUpdatesFlag = false;

  /** Private tab index. */
  _headerTabIndex = 0;

  /** The selected tab index/init. */
  @Input() set headerTabIndex(value: number) {
    this._headerTabIndex = value;
  }

  /**
   * Get selected tab index/init.
   * @returns A boolean.
   */
  get headerTabIndex(): number {
    return this._headerTabIndex;
  }

  /** Station bucket questions. */
  allBucketQuestions = input<StationBucketQuestion[]>([]);

  /** Any changes made to to inputFrameWidgetItems should be sent to station component. */
  @Output() getAllInputFrameWidgets = new EventEmitter<GridsterItemWidget[]>();

  /** Restore bucket data when cancel changes. */
  @Output() restoreBucketData = new EventEmitter<void>();

  /** Whether the request to be made to get All widgets. */
  @Output() getAllStationWidgets = new EventEmitter();

  /** Event to open settings section for the selected question. */
  @Output() openSettingSection = new EventEmitter<{
    /** The object to be configured. */
    objectToConfig:
      | Question
      | CustomIdSettings
      | GridsterItemWidget
      | StationBucketQuestion;
    /** The type of the configurable object's container. */
    containerWidgetType: WidgetType;
    /** If the question is belongs to bucket or not. */
    isBucketQuestion: boolean;
    /** If the question is already present in grid or not. */
    bucketQuestionNotInGrid: boolean;
  }>();

  /** Event to open dashboard widget settings section. */
  @Output() openDashboardWidgetSettings = new EventEmitter<{
    /** Widget item for which settings should open. */
    widgetItem: GridsterItemWidget;
    /** Index of the  widget. */
    widgetIndex: number;
    /** Number of items to be displayed in the widget. */
    quantityElementsWidget: number;
  }>();

  /** Whether the grid is in edit mode or not. */
  @Output() isEditMode = new EventEmitter<boolean>();

  /** Whether library and component tree has to be updated or not. */
  @Output() checkLibraryTab = new EventEmitter();

  /** Whether bucket list has to be updated or not. */
  @Output() checkBucketTab = new EventEmitter();

  /** Any changes made to to configurableObject should be sent to station component. */
  @Output() configurableData = new EventEmitter<
    ConfigurableObject | undefined
  >();

  /** Any changes made to to inputFramesList should be sent to station component. */
  @Output() inputFramesList = new EventEmitter<string[]>();

  /** The form to add to station. */
  stationGridForm: FormGroup<{
    /** Input to get data of frames in grid. */
    inputFrameFieldForm: FormControl<string | null>;
  }>;

  /** Different types of input frames components.*/
  widgetType = WidgetType;

  /** Images RithmId To Delete. */
  imagesRithmIdToDelete: DeleteImageTemp[] = [];

  /** Grid initial values. */
  options: GridsterConfig = {
    gridType: GRID_TYPE,
    fixedRowHeight: FIXED_ROW_HEIGHT,
    displayGrid: DISPLAY_GRID,
    pushItems: true,
    pushResizeItems: true,
    draggable: {
      enabled: true,
      ignoreContent: true,
    },
    resizable: {
      enabled: true,
    },
    keepFixedHeightInMobile: true,
    itemResizeCallback: StationComponent.itemResize,
    margin: MARGIN,
    minCols: MIN_COLS,
    maxCols: MAX_COLS,
    maxRows: MAX_ROWS,
    maxItemRows: MAX_ITEM_ROWS,
    allowMultiLayer: true,
    defaultLayerIndex: 1,
    maxLayerIndex: 2,
    baseLayerIndex: 1,
    disableWarnings: true,
  };

  /** Powers by questions. */
  questionAsCondition: Power[] = [];

  /** Get the widget type for the setting tab. */
  widgetTypeToConfig!: WidgetType;

  /** Input frame widgets items copy. */
  inputFrameWidgetItemsCopy: GridsterItemWidget[] = [];

  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** Question type. */
  questionFieldType = QuestionFieldType;

  /** Configurable object to send for settings section. */
  configurableObject: ConfigurableObject | undefined = undefined;

  /** Container label. */
  containerLabelDefault!: string;

  /** Widget id from popper js is opened. */
  widgetIdPopperIsOpened = '';

  /** Edit mode toggle for station. */
  editMode = false;

  /** Flag that show if is layout mode. */
  layoutMode = true;

  /** Flag that show if is setting mode. */
  settingMode = false;

  /** Flag showing if the right drawer is open. */
  isOpenDrawerLeft = false;

  /** Flag to indicate whether the focus is on a text component or not. */
  showTextAlignIcons = false;

  /** Show quick tip on the grid when it is without elements. */
  showQuickTipOnGrid = false;

  /** Show only button delete widget in drawer. */
  deleteWidget = false;

  /** Whether the request to get the widgets is currently underway. */
  widgetLoading = false;

  /** If selected question is bucket question or not. */
  isBucketQuestion = false;

  /** If selected question is bucket question or not. */
  bucketQuestionNotInGrid = false;

  /** Show warning message if prompt is empty for any question. */
  isEmptyPrompt = false;

  /** Save button status. */
  saveButtonStatus = true;

  /** Is loading power for question. */
  isLoadingPowerQuestion = false;

  /** The current focused/selected widget. */
  widgetFocused = -1;

  /** Indicates when the button to move the widget will be enabled. */
  widgetMoveButton = -1;

  /**
   * Prevent function.
   * @param event Event.
   */
  prevent = (event: BeforeUnloadEvent): void => {
    if (this.editMode) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  /** Current Field rithmId Selected. */
  currentFieldSelected = '';

  /** True if the user is architect else False. */
  isArchitect = false;

  /** Invalid question object. */
  invalidQuestion!: Question;

  /** Whether it is necessary to save the required assignment or not. */
  saveAssignmentStatus: boolean | undefined = undefined;

  constructor(
    private fb: FormBuilder,
    private stationService: StationService,
    private dialog: MatDialog,
    private boardService: BoardService,
    private sidenavDrawerService: SidenavDrawerService,
    private popupService: PopupService,
    private errorService: ErrorService,
    private inputFrameHelper: InputFrame,
    public mobileBrowserChecker: MobileBrowserChecker,
    private dashboardWidgets: DashboardWidgets,
    private containerService: ContainerService,
    private powerService: PowerService,
    private userService: UserService,
  ) {
    this.stationGridForm = this.fb.group({
      inputFrameFieldForm: this.fb.control(''),
    });
    this.windowResize();
  }

  /** Set required on questions self assign. */
  requiredAssign$(): void {
    this.stationService.requiredAssign$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((status: boolean) => {
        const field = this.inputFrameWidgetItems
          .flatMap((item) => item.questions || [])
          .find(
            (question) =>
              // If equal to selfAssign.
              question.questionType === QuestionFieldType.SelfAssign &&
              // If equal to the current station.
              question.originalStationRithmId === this.stationRithmId,
          );
        if (field) {
          field.isRequired = status;
          this.saveAssignmentStatus = status;
        }
      });
  }

  /** Subscribe to recalculate height frame. */
  private subscribeRecalculateDimensionsFrame$(): void {
    this.stationService.recalculateDimensionsFrame$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: InputFrameUpdatedEvent) => {
        switch (event.action) {
          case ActionTypeFrameDimensionEvent.Resize:
            this.gridItem?.gridster &&
              this.calculateRowsFrame(undefined, event.questionRithmId);
            break;
          case ActionTypeFrameDimensionEvent.TransferArrayItem:
            event.currentFrameRithmId &&
              this.calculateRowsFrame(event.currentFrameRithmId);
            event.previousFrameRithmId &&
              this.calculateRowsFrame(
                event.previousFrameRithmId,
                undefined,
                true,
              );
            break;
          case ActionTypeFrameDimensionEvent.Remove:
            this.calculateRowsFrame(event.currentFrameRithmId, undefined, true);
            break;
        }
      });
  }

  /**
   * Resize the frames that contains checklist and radio list questions.
   */
  private resizeFramesWithCheckListRadioList(): void {
    this.inputFrameWidgetItems.map((frame) => {
      if (
        frame.questions?.some((question) =>
          this.checkRadioListQuestionType.includes(question.questionType),
        )
      ) {
        const list = frame.questions?.filter((que) =>
          this.checkRadioListQuestionType.includes(que.questionType),
        );
        list.map((checkOrRadioQuestion) => {
          checkOrRadioQuestion.size = {
            height: this.inputFrameHelper.calculateHeightList(
              checkOrRadioQuestion.possibleAnswers || [],
            ),
            maxHeight: this.inputFrameHelper.calculateHeightList(
              checkOrRadioQuestion.possibleAnswers || [],
            ),
          };
        });
        this.calculateRowsFrame(frame.rithmId);
      }
    });
  }

  /**
   * Calculate row and update rows for frame.
   * @param frameId Frame id.
   * @param questionId Question id.
   * @param setOnlyMinRow If should only set minRow.
   */
  calculateRowsFrame(
    frameId = '',
    questionId = '',
    setOnlyMinRow = false,
  ): void {
    const index = frameId
      ? this.findFrameIndex(frameId)
      : this.findFrameIndexByQuestion(questionId);

    if (index > -1) {
      const questions = this.inputFrameWidgetItems[index].questions;
      const rows = questions
        ? this.inputFrameHelper.calculateRowFrame(questions)
        : 1;
      this.inputFrameWidgetItems[index] =
        !setOnlyMinRow && this.gridItem.gridster.grid[index].$item.rows < rows
          ? {
              ...this.inputFrameWidgetItems[index],
              rows,
              minItemRows: rows,
            }
          : {
              ...this.inputFrameWidgetItems[index],
              minItemRows: rows,
            };

      const gridster = this.gridItem?.gridster.grid[index];
      this.gridItem.gridster.grid[index].$item.rows = rows;
      const itemResized = new GridsterPush(this.gridItem.gridster.grid[index]);

      itemResized.pushItems(itemResized.fromNorth) &&
        this.actionsForGridElements(gridster, itemResized);

      itemResized.destroy();
      this.changedOptions();
    }
  }

  /** Listen to subscribeEditModeOnGrid$. */
  private subscribeEditModeOnGrid$(): void {
    this.stationService.editModeOnGrid$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((editMode) => {
        this.setEditMode(editMode);
        this.enableSaveButton();
      });
  }

  /**
   * Listen the subscribeUpdatedTemplateGridObject subject.
   */
  private subscribeUpdatedTemplateGridObject(): void {
    this.stationService.updatedTemplateGridObject$
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (updatedObject) => {
          const parentIndex = updatedObject.settingObject.containerIndex;
          if (updatedObject.stationRithmId === this.stationRithmId) {
            if (updatedObject.isRemoving) {
              if (updatedObject.settingObject.containerWidgetType !== 'input') {
                this.removingWidgetProcess(parentIndex as number);
              } else {
                if (updatedObject.settingObject.isBucketQuestion) {
                  this.configurableData.emit(undefined);
                } else {
                  this.removeQuestionFromFrame(
                    updatedObject.settingObject.target as Question,
                    parentIndex as number,
                  );
                }
              }
            }
          }
        },
      });
  }

  /** Subscribe to imagesRithmIdToDelete$. */
  private subscribeDeleteImageTemp$(): void {
    this.boardService.imagesRithmIdToDelete$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((image) => {
        this.imagesRithmIdToDelete = this.dashboardWidgets.getImagesDeleteTemp(
          this.imagesRithmIdToDelete,
          image,
        );
      });
  }

  /** Subscribe to check when a custom field or question selectable is configured to issue its status. */
  private subscribeDisableSaveButton(): void {
    this.stationService.disableSaveButton$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.enableSaveButton();
      });
  }

  /** Update data in specific widget. */
  private subscribeDrawerDataWidget$(): void {
    this.boardService.updateDataWidget$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((editDataWidget) => {
        const index = this.inputFrameWidgetItems.findIndex(
          ({ rithmId }) => rithmId === editDataWidget.widgetItem.rithmId,
        );
        if (index > -1) {
          this.inputFrameWidgetItems[index] = editDataWidget.widgetItem;
        }
      });
  }

  /** Subscribe to the subject resetGridChanges$. */
  private subscribeResetGridChanges$(): void {
    this.stationService.resetGridChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.resetGridChanges();
      });
  }

  /**
   * Gets info about the station widget grid.
   */
  ngOnInit(): void {
    this.inputFrameWidgetItemsCopy = JSON.parse(
      JSON.stringify(this.inputFrameWidgetItems),
    );
    this.subscribeEditModeOnGrid$();
    this.subscribeUpdatedTemplateGridObject();
    this.subscribeDisableSaveButton();
    this.setConfigMobileGridster();
    this.setEditMode(false);
    this.preventReload();
    this.subscribeDrawerDataWidget$();
    this.subscribeDeleteImageTemp$();
    this.subscribeResetGridChanges$();
    this.getContainerLabel();
    this.subscribeRecalculateDimensionsFrame$();
    this.requiredAssign$();
    this.popperOpened$();
    this.isArchitect = this.userService.isAdmin;
  }

  /** Subject popper opened. */
  private popperOpened$(): void {
    this.containerService.popperQuestionOpened$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((id) => {
        this.widgetIdPopperIsOpened = id;
      });
  }

  /**
   * Prevent close tab.
   */
  private preventReload(): void {
    window.addEventListener('beforeunload', this.prevent);
  }

  /**
   * Needed to resize a mobile browser when the scrollbar hides.
   */
  windowResize(): void {
    fromEvent(window, 'resize')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.setConfigMobileGridster();
      });
  }

  /**
   * Whether the screen width is lesser than 640px.
   * @returns True if width is lesser than 640px.
   */
  get isMobileView(): boolean {
    return window.innerWidth <= 640;
  }

  /**
   * Get whether widget type text or not.
   * @returns A boolean.
   */
  get widgetTypeText(): boolean {
    return this.textWidgetTypes.includes(this.widgetTypeToConfig);
  }

  /**
   * Whether the screen width is lesser than 640px.
   * @returns True if width is lesser than 640px.
   */
  get nonEditableStation(): boolean {
    return window.innerWidth <= 1024;
  }

  /**
   * Validate the conditions to display the Save or Save Rules button.
   * @returns If display the button, can be true or false.
   */
  get stationInputFrames(): Question[] {
    let dataFiltered = [] as Question[];
    if (this.inputFrameWidgetItems) {
      this.inputFrameWidgetItems.map((frame) => {
        if (frame?.questions && frame.widgetType === WidgetType.InputWidget) {
          dataFiltered = dataFiltered.concat(frame.questions);
        }
      });
    }

    return dataFiltered;
  }

  /**
   * Get the questions rithmId array by frame.
   * @param indexFrame Index frame.
   * @returns Array with id the questions into frame.
   */
  getQuestionsRithmId(indexFrame: number): string[] {
    return (
      this.inputFrameWidgetItems[indexFrame].questions?.map(
        ({ rithmId }) => rithmId,
      ) || []
    );
  }

  /**
   * Validate if a widget type is station related or not.
   * @param widgetType The widget for evaluate.
   * @returns The TRUE if it's of station else FALSE.
   */
  isStationWidget(widgetType: WidgetType): boolean {
    return [
      WidgetType.Station,
      WidgetType.StationTableBanner,
      WidgetType.StationMultiline,
      WidgetType.StationMultilineBanner,
    ].includes(widgetType);
  }

  /**
   * Validate if a widget type is container related or not.
   * @param widgetType The widget for evaluate.
   * @returns The TRUE if it's of container else FALSE.
   */
  isContainerWidget(widgetType: WidgetType): boolean {
    return [
      WidgetType.Container,
      WidgetType.ContainerListBanner,
      WidgetType.ContainerProfileBanner,
    ].includes(widgetType);
  }

  /**
   * Verify if the question was clicked from the library tree or the bucket tree.
   * Also determined if the question clicked is a CustomId type or not.
   * @param question Question added.
   * @param indexSelected Tab index selected from the left column.
   */
  checkQuestionAdded(question: Question, indexSelected: string): void {
    let thereIsNotSetting = false;
    let isCustomIdInvalid = false;
    let isFunctionInvalid = false;
    let isInvalidField = false;

    indexSelected === '1'
      ? this.checkBucketQuestions()
      : this.checkLibraryTabQuestions();

    /** These are the field types that should have their configuration with some required fields. */
    const fieldRequired = [
      QuestionFieldType.CustomId,
      QuestionFieldType.Function,
    ].includes(question.questionType);

    thereIsNotSetting = fieldRequired && !question.settings?.length;

    isCustomIdInvalid = !!(
      question.settings?.length &&
      question.questionType === QuestionFieldType.CustomId &&
      this.inputFrameHelper.isCustomIdInvalid(question.settings)
    );

    isFunctionInvalid = !!(
      question.settings?.length &&
      question.questionType === QuestionFieldType.Function &&
      this.inputFrameHelper.isFunctionInvalid(question.settings)
    );

    isInvalidField = fieldRequired && (isCustomIdInvalid || isFunctionInvalid);

    /** We must evaluate if it does not have settings and if it is invalid.*/
    if (thereIsNotSetting || isInvalidField) {
      this.stationGridForm.controls.inputFrameFieldForm.setErrors({
        valid: false,
      });
    }

    /** When there is an invalid selectable question and a new one is added, it must be checked whether or not to validate the button. */
    if (this.questionTypeSelectable.includes(question.questionType)) {
      setTimeout(() => {
        this.enableSaveButton();
      }, 100);
    }
  }

  /**
   * Initialization the edit mode and layout config.
   * @param mode Whether is edit mode or not.
   */
  setEditMode(mode: boolean): void {
    this.editMode = mode;
    this.isEditMode.emit(this.editMode);
    this.setGridMode(mode ? 'layout' : 'preview');
    if (this.editMode) {
      setTimeout(() => {
        this.resizeFramesWithCheckListRadioList();
        this.enableSaveButton();
      }, 100);
    }
  }

  /** Set config break point in mobile. */
  private setConfigMobileGridster(): void {
    this.options.mobileBreakpoint = this.mobileBrowserChecker.isMobileDevice()
      ? 1920
      : 768;
    this.changedOptions();
  }

  /**
   * Set the grid mode for station edition.
   * @param mode Value of the grid mode of the toolbarEditStation buttons.
   */
  setGridMode(mode: 'layout' | 'preview'): void {
    let enabledMode = false;
    /** Depending on the case, the mode is set. */
    switch (mode) {
      case 'preview':
        this.editMode = false;
        this.layoutMode = false;
        this.settingMode = false;
        this.isOpenDrawerLeft = false;
        this.closeSettingDrawer();
        this.showTextAlignIcons = false;
        this.currentFieldSelected = '';
        break;
      case 'layout':
        enabledMode = true;
        this.closeSettingDrawer();
        this.showTextAlignIcons = false;
        this.showQuickTipOnGrid = true;
        break;
      default:
        break;
    }
    this.layoutMode = enabledMode;
    this.settingMode = !enabledMode;

    /* Make the grid visible.*/
    this.options.displayGrid = enabledMode ? 'always' : 'none';
    /* Resizing is performed. */
    if (this.options.resizable) {
      this.options.resizable.enabled = enabledMode;
    }
    /* Rearranges, can be dragged. */
    if (this.options.draggable) {
      this.options.draggable.enabled = enabledMode;
    }
    this.widgetFocused = -1;
    /* Execute changes. */
    this.changedOptions();
  }

  /**
   * Change options in grid.
   */
  changedOptions(): void {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

  /**
   * Actions for grid elements.
   * @param gridItem Element of the current grid.
   * @param itemResized Element of the current grid.
   */
  actionsForGridElements(
    gridItem: GridsterItemComponentInterface,
    itemResized: GridsterPush,
  ): void {
    itemResized.checkPushBack(); // check for items can restore to original position
    itemResized.setPushedItems(); // save the items pushed
    gridItem.setSize();
    gridItem.checkItemChanges(gridItem.$item, gridItem.item);
  }

  /**
   * Close the right setting drawer for field setting.
   */
  closeSettingDrawer(): void {
    /** If both are open, the field setting drawer must be closed. */
    if (
      this.sidenavDrawerService.isDrawerOpen &&
      this.drawerContext === 'fieldSetting'
    ) {
      this.sidenavDrawerService.closeDrawer();
    }
  }

  /**
   * Allow to select/unselect any clicked widget.
   * @param index The index of the selected widget.
   */
  focusWidget(index: number): void {
    this.widgetFocused = index === this.widgetFocused ? -1 : index;
  }

  /**
   * Can the field be moved up.
   * @param fieldIndex The index of the field.
   * @returns True if the field can be moved up.
   */
  canFieldMoveUp(fieldIndex: number): boolean {
    return fieldIndex === 0
      ? false
      : this.fields.length - 1 === fieldIndex
        ? true
        : true;
  }

  /**
   * Can the field be moved down.
   * @param fieldIndex The index of the field.
   * @returns True if the field can be moved down.
   */
  canFieldMoveDown(fieldIndex: number): boolean {
    return fieldIndex === 0 ? true : this.fields.length - 1 !== fieldIndex;
  }

  /**
   * Moves a field up or down within the grid.
   * @param direction The direction to move the field.
   * @param index The current index of the field in the list.
   */
  move(direction: 'up' | 'down', index: number): void {
    const questionToMove = this.fields.splice(index, 1);
    const directionToMove = direction === 'up' ? -1 : 1;
    this.fields.splice(index + directionToMove, 0, questionToMove[0]);
    this.stationService.touchStationForm();
  }

  /**
   * Move a previous question field in the grid area back to its initial expansion panel.
   * @param field The field to be moved.
   */
  remove(field: Question): void {
    const index = this.fields.indexOf(field);
    this.fields.splice(index, 1);
    if (this.stationRithmId !== field.originalStationRithmId) {
      this.movingQuestion(field);
    }
    this.stationService.touchStationForm();
  }

  /**
   * Moves a field from the grid area to previous fields.
   * @param field The field to be moved.
   */
  movingQuestion(field: Question): void {
    this.stationService.moveQuestion(field);
  }

  /**
   * The `onTouched` function.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};

  /**
   * Writes a value to this form.
   * @param val The value to be written.
   */
  // eslint-disable-next-line
  writeValue(val: any): void {
    val && this.stationGridForm.setValue(val, { emitEvent: false });
  }

  /**
   * Registers a function with the `onChange` event.
   * @param fn The function to register.
   */
  // eslint-disable-next-line
  registerOnChange(fn: any): void {
    // TODO: check for memory leak
    this.stationGridForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(fn);
  }

  /**
   * Registers a function with the `onTouched` event.
   * @param fn The function to register.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Sets the disabled state of this form control.
   * @param isDisabled The disabled state to set.
   */
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.stationGridForm.disable() : this.stationGridForm.enable();
  }

  /**
   * Reports whether this form control is valid.
   * @returns Validation errors, if any.
   */
  validate(): ValidationErrors | null {
    return this.stationGridForm.valid
      ? null
      : {
          invalidForm: {
            valid: false,
            message: `${TermsGeneric.Station.Single} grid form is invalid`,
          },
        };
  }

  /** Hide quick tip on grid. */
  hideQuickTipOnGrid(): void {
    if (
      !this.inputFrameWidgetItems.length &&
      this.showQuickTipOnGrid &&
      this.editMode
    ) {
      this.showQuickTipOnGrid = false;
    }
  }

  /**
   * Will add a new input frame in the station grid.
   * @param event Information referent to widget selected.
   */
  addInputFrame(
    event: CdkDragDrop<string, string, WidgetType> | WidgetType,
  ): void {
    const gridsterItemCore: GridsterItemWidget = {
      rithmId: uuidv4(),
      cols: 1,
      rows: 1,
      x: 0,
      y: 0,
      widgetType: WidgetType.InputWidget,
      data: '',
      id: this.inputFrameWidgetItems.length,
    };

    const textFramesDefaultData: TextWidgetInformation = {
      data: '',
      xAlign: TextWidgetAlignmentType.HorizontalLeft,
      yAlign: TextWidgetAlignmentType.VerticalTop,
    };

    const type: string = typeof event === 'string' ? event : event.item.data;

    /**Add individual properties for every Type. */
    switch (type) {
      case WidgetType.InputWidget:
        gridsterItemCore.cols = 6;
        gridsterItemCore.rows = 1;
        gridsterItemCore.minItemRows = MIN_ROW_FRAME;
        gridsterItemCore.minItemCols = 6;
        gridsterItemCore.questions = [];
        gridsterItemCore.data = JSON.stringify({
          stationRithmId: this.stationRithmId,
        });
        this.inputFrameList.push(
          'inputFrameWidget-' + gridsterItemCore.rithmId,
        );
        break;

      case WidgetType.BodyWidget:
        gridsterItemCore.cols = 6;
        gridsterItemCore.rows = 2;
        gridsterItemCore.minItemCols = 4;
        gridsterItemCore.minItemRows = MIN_ROW_FRAME;
        gridsterItemCore.widgetType = WidgetType.BodyWidget;
        gridsterItemCore.data = JSON.stringify(textFramesDefaultData);
        break;

      case WidgetType.ImageWidget:
        gridsterItemCore.cols = 6;
        gridsterItemCore.rows = 2;
        gridsterItemCore.minItemCols = 6;
        gridsterItemCore.minItemRows = 2;
        gridsterItemCore.widgetType = WidgetType.ImageWidget;
        gridsterItemCore.data = JSON.stringify({
          vaultFileId: '',
          fillType: ImageMode.Cover,
          label: '',
        } as ImageWidgetData);
        break;
      case WidgetType.CircleImageWidget:
        gridsterItemCore.cols = 4;
        gridsterItemCore.rows = 1;
        gridsterItemCore.minItemCols = 4;
        gridsterItemCore.minItemRows = MIN_ROW_FRAME;
        gridsterItemCore.widgetType = WidgetType.CircleImageWidget;
        break;
      case WidgetType.CustomIdWidget:
      case QuestionFieldType.CustomId:
        gridsterItemCore.cols = 6;
        gridsterItemCore.rows = 1;
        gridsterItemCore.minItemCols = 6;
        gridsterItemCore.maxItemCols = 24;
        gridsterItemCore.maxItemRows = 1;
        gridsterItemCore.widgetType = WidgetType.CustomIdWidget;
        gridsterItemCore.data = JSON.stringify({
          rithmId: uuidv4(),
          prefix: '',
          delimiter: '',
          suffixLength: 1,
          suffixType: '',
          generatorMode: '',
          lastUsedSuffix: '',
          label: 'Custom ID',
        });
        break;
      case WidgetType.ParentChildWidget:
        gridsterItemCore.cols = 6;
        gridsterItemCore.rows = 3;
        gridsterItemCore.minItemCols = 6;
        gridsterItemCore.minItemRows = 3;
        gridsterItemCore.widgetType = WidgetType.ParentChildWidget;
        break;
      default:
        break;
    }

    if (
      typeof event !== 'string' &&
      !Object.values(WidgetType).includes(type as WidgetType) &&
      !Object.values(QuestionFieldType).includes(type as QuestionFieldType)
    ) {
      const widget = event.item.data as unknown as GridsterItemWidget;
      const widgetData = JsonValidator.getObjectFromString<FiltersDataModel>(
        widget.data,
      );

      widgetData.prompt =
        !widgetData.isPublished &&
        !this.allBucketQuestions().some(
          ({ rithmId }) => rithmId === widget.rithmId,
        )
          ? 'Untitled ' + widgetData.prompt
          : widgetData.prompt;

      if (
        widgetData.sorting &&
        typeof widgetData.sorting === 'object' &&
        !Array.isArray(widgetData.sorting)
      ) {
        const singleSorting = widgetData.sorting as SortingWidgetData;
        widgetData.sorting =
          singleSorting && singleSorting.sortBy?.length ? [singleSorting] : [];
      }
      widget.data = JSON.stringify(widgetData);
      this.addGridsterItem(widget);
      return;
    }

    this.inputFrameWidgetItems.push(gridsterItemCore);
    this.inputFramesList.emit(this.inputFrameList);
    this.getAllInputFrameWidgets.emit(this.inputFrameWidgetItems);
  }

  /**
   * Add elements to the widget type grid.
   * @param gridsterItemCore Widget selected.
   */
  private addGridsterItem(gridsterItemCore: GridsterItemWidget): void {
    /** When the widget is published, the rithmId and the widgetRithmId must be different. */
    if (gridsterItemCore.rithmId === gridsterItemCore.originalWidgetRithmId)
      gridsterItemCore.rithmId = uuidv4();

    gridsterItemCore.maxItemCols = undefined;
    gridsterItemCore.maxItemRows = undefined;

    switch (gridsterItemCore.widgetType) {
      case WidgetType.Container:
      case WidgetType.Station:
      case WidgetType.StationGroupSearch:
      case WidgetType.StationGroupTraffic:
        gridsterItemCore.minItemCols = 6;
        gridsterItemCore.minItemRows = 4;
        gridsterItemCore.cols = 6;
        gridsterItemCore.rows = 4;
        break;

      case WidgetType.ContainerListBanner:
      case WidgetType.ContainerProfileBanner:
      case WidgetType.StationMultiline:
      case WidgetType.StationMultilineBanner:
      case WidgetType.StationTableBanner:
      case WidgetType.RelationshipWidget:
        gridsterItemCore.minItemCols = 6;
        gridsterItemCore.minItemRows = 6;
        gridsterItemCore.cols = 6;
        gridsterItemCore.rows = 6;
        break;

      case WidgetType.PreBuiltStation:
      case WidgetType.PreBuiltContainer:
        gridsterItemCore.minItemCols = 8;
        gridsterItemCore.minItemRows = 4;
        gridsterItemCore.cols = 8;
        gridsterItemCore.rows = 4;
        break;

      default:
        gridsterItemCore.minItemCols = 6;
        gridsterItemCore.minItemRows = 4;
        gridsterItemCore.cols = 6;
        gridsterItemCore.rows = 4;
        break;
    }

    this.inputFrameWidgetItems.push(gridsterItemCore);

    /** Open drawer when they are generic widgets. */
    if (
      LibraryHelper.genericWidgetTypes.includes(
        gridsterItemCore.widgetType as GenericWidget,
      )
    ) {
      this.openDashboardWidgetSetting(
        gridsterItemCore,
        this.inputFrameWidgetItems.length - 1,
        0,
      );
      gridsterItemCore.widgetType === WidgetType.RelationshipWidget &&
        this.stationService.disableSaveButton$.next();
    }
  }

  /**
   * Remove widget from notification in widget component error.
   * @param widgetItem Widget item for delete.
   */
  removeWidget(widgetItem: GridsterItemWidget | CustomIdSettings): void {
    this.deleteWidget = true;
    this.widgetFocused = this.inputFrameWidgetItems.findIndex(
      (e) => e.rithmId === widgetItem.rithmId,
    );
    this.removeWidgetsHandler();
  }

  /**
   * Open confirm remove question modal.
   * @returns Value to confirm.
   */
  async openConfirmModal(): Promise<boolean> {
    return firstValueFrom(
      this.dialog
        .open(ConfirmRemoveQuestionFrameModalComponent, {
          panelClass: ['w-11/12', 'md:w-2/4', 'h-2/3', 'md:h-2/4'],
          maxWidth: '560px',
          disableClose: false,
          data: {
            powers: this.questionAsCondition,
          },
        })
        .afterClosed()
        .pipe(
          catchError(() => of(false)),
          first(),
        ),
    );
  }

  /**
   * Confirm remove frame.
   * @returns The confirm status.
   */
  async confirmRemoveFrame(): Promise<boolean> {
    if (this.questionAsCondition.length) {
      return this.openConfirmModal();
    }

    //If so, The user is removing the widget from the toolbar and need to confirm.
    return this.popupService.confirm({
      title: 'Remove widget?',
      message: 'You are about to remove this widget from the grid.',
      okButtonText: 'Remove',
      cancelButtonText: 'Cancel',
      important: true,
    });
  }

  /**
   * Remove widgets from the gridster in layout mode.
   */
  async removeWidgetsHandler(): Promise<void> {
    this.questionAsCondition = [];

    if (
      this.inputFrameWidgetItems[this.widgetFocused].widgetType ===
      WidgetType.InputWidget
    ) {
      const questionsRithmId = this.getQuestionsRithmId(this.widgetFocused);

      if (questionsRithmId.length) {
        await this.getPowerByQuestions(questionsRithmId);
      }
    }

    (await this.confirmRemoveFrame()) &&
      this.removingWidgetProcess(this.widgetFocused);

    if (this.widgetFocused > -1) {
      this.deleteWidget = false;
    }
  }

  /**
   * The process of removing widgets from the station grid.
   * @param index The index of the widget to remove.
   */
  private removingWidgetProcess(index: number): void {
    if (index <= -1) return;
    const frame = this.inputFrameWidgetItems[index];
    const frameId = frame?.rithmId;
    const frameReference = this.inputFrameList.find(
      (currentFrame) => currentFrame === `inputFrameWidget-${frameId}`,
    );

    frame.questions?.forEach((question) => {
      if (question.questionType === QuestionFieldType.ContainerName) {
        const name = TermsGeneric.Container.Single;
        question.prompt = name;
        this.containerService.updatedContainerLabelText({
          rithmId: this.stationRithmId,
          value: name,
        });
        this.containerService.updateQuestionFieldValueById({
          rithmId: this.stationRithmId,
          value: name,
        });
        this.stationService.updateContainerLabelFromStation$.next(name);
      }

      this.stationService.bucketQuestionUpdate(question);
    });
    this.enableSaveButton();
    this.inputFrameWidgetItems.splice(index, 1);
    if (frameReference) {
      this.inputFrameList.splice(
        this.inputFrameList.indexOf(frameReference),
        1,
      );
    }
    this.inputFramesList.emit(this.inputFrameList);
    this.widgetFocused = -1;
    this.configurableObject = undefined;
    this.configurableData.emit(this.configurableObject);
    this.checkLibraryTabQuestions();
    this.checkBucketQuestions();
    this.changedOptions();
  }

  /**
   * This save button clicked show confirm If no questions
   * and Save or update the changes to the station frame widgets.
   */
  async saveStationWidgetChanges(): Promise<void> {
    this.showTextAlignIcons = false;
    this.isEmptyPrompt = false;
    this.configurableObject = undefined;
    let hasNoQuestions = this.inputFrameWidgetItems.some(
      (field) =>
        !field.questions?.length && field.widgetType === WidgetType.InputWidget,
    );
    if (hasNoQuestions) {
      const confirm = await this.popupService.confirm({
        title: 'Are you sure?',
        message: 'You have empty input frames, would you like to save anyway?',
        okButtonText: 'Yes',
        cancelButtonText: 'No',
        important: true,
      });
      // Data emit can be done after displaying popup to avoid changes happening in station-component affect here.
      this.configurableData.emit(this.configurableObject);
      if (confirm) {
        this.saveStationWidgetsChanges();
        hasNoQuestions = false;
      }
    } else {
      this.configurableData.emit(this.configurableObject);
      if (this.inputFrameWidgetItems.length) {
        // If isEmptyPrompt TRUE, should return from current function as there may be question with empty prompt.
        const frameQuestions = await InputFrame.extractFrameQuestions(
          this.inputFrameWidgetItems,
        );

        if (frameQuestions.length) {
          if (frameQuestions.some((question) => !question.prompt.length)) {
            this.isEmptyPrompt = true;
            return;
          }
        }
      }
      this.saveStationWidgetsChanges();
    }
  }

  /**
   * Save or update the changes make the station frame widgets.
   */
  private saveStationWidgetsChanges(): void {
    this.widgetLoading = true;
    this.checkImageGridsterItem();
    this.setEditMode(false);
    const frames = this.inputFrameWidgetItems.some(({ widgetType }) =>
      LibraryHelper.genericWidgetTypes.includes(widgetType as GenericWidget),
    )
      ? this.inputFrameWidgetItems.map((item) => {
          const isGenericWidget = LibraryHelper.genericWidgetTypes.includes(
            item.widgetType as GenericWidget,
          );
          let data = item.data;
          if (isGenericWidget) {
            const widgetData =
              JsonValidator.getObjectFromString<FiltersDataModel>(item.data);

            if (Array.isArray(widgetData.filters)) {
              const newObject = {
                ...widgetData,
                filters: this.dashboardWidgets.getFiltersParsed(
                  widgetData.filters,
                ),
              };
              data = JSON.stringify(newObject);
            }

            if (!widgetData.isPublished) {
              item.originalStationRithmId = this.stationRithmId;
            }
          }

          return {
            ...item,
            data,
          };
        })
      : this.inputFrameWidgetItems;
    // Request to save widgets.
    const saveStationWidget$ = this.stationService.saveStationWidgets(
      this.stationRithmId,
      frames,
      true,
    );
    // Request to make assignment required, optional.
    const updateAssignment$ =
      this.saveAssignmentStatus !== undefined
        ? this.stationService.updateAssignmentParamStation(
            this.stationRithmId,
            this.saveAssignmentStatus,
          )
        : of(null);

    forkJoin([saveStationWidget$, updateAssignment$])
      .pipe(first())
      .subscribe({
        next: () => {
          this.getAllStationWidgets.emit();
          this.setGridMode('preview');
          this.deleteTempImages(true);
          this.widgetLoading = false;
        },
        error: (error: unknown) => {
          this.widgetLoading = false;
          this.setEditMode(true);
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Get powers by questions.
   * @param questionsRithmId Questions id for get powers.
   */
  async getPowerByQuestions(questionsRithmId: string[]): Promise<void> {
    this.isLoadingPowerQuestion = true;

    this.questionAsCondition = await firstValueFrom(
      this.powerService
        .getPowerByQuestions(this.stationRithmId, questionsRithmId)
        .pipe(
          catchError(() => of([] as Power[])),
          first(),
        ),
    );

    this.isLoadingPowerQuestion = false;
  }

  /**
   * Check if gridsterItem with image exists or not.
   */
  private checkImageGridsterItem(): void {
    this.inputFrameWidgetItems.map((gridsterItem) => {
      if (gridsterItem?.imageId) {
        const gridsterData = {
          ...JSON.parse(gridsterItem.data),
          imageId: gridsterItem.imageId,
          imageName: gridsterItem.imageName,
        };
        gridsterItem.data = JSON.stringify(gridsterData);
      }
    });
  }

  /**
   * Open in the right column the configuration of the selected widget.
   * @param objectToConfig The object to be configured.
   * @param containerWidgetType The type of the configurable object's container.
   * @param isBucketQuestion If the question is belongs to bucket or not.
   * @param bucketQuestionNotInGrid If the question is belongs to bucket or not.
   */
  openSettingTab(
    objectToConfig:
      | Question
      | CustomIdSettings
      | GridsterItemWidget
      | StationBucketQuestion,
    containerWidgetType: WidgetType,
    isBucketQuestion = false,
    bucketQuestionNotInGrid = false,
  ): void {
    this.currentFieldSelected = objectToConfig.rithmId;
    this.configurableObject = undefined;
    this.configurableData.emit(this.configurableObject);
    this.widgetTypeToConfig = containerWidgetType;
    this.openSettingSection.emit({
      objectToConfig,
      containerWidgetType,
      isBucketQuestion,
      bucketQuestionNotInGrid,
    });
    this.configurableObject = {
      target: objectToConfig,
    };
  }

  /**
   * Toggle drawer of the station widget.
   * @param widgetItem String of the data station.
   * @param widgetIndex Number of the position the widget.
   * @param widgetData Station widget data.
   */
  openDashboardWidgetSetting(
    widgetItem: GridsterItemWidget,
    widgetIndex: number,
    widgetData: StationWidgetData | number,
  ): void {
    this.currentFieldSelected = '';
    this.openDashboardWidgetSettings.emit({
      widgetItem,
      widgetIndex,
      quantityElementsWidget:
        typeof widgetData === 'number'
          ? widgetData
          : widgetData.containers.length,
    });

    if (widgetItem.widgetType === WidgetType.RelationshipWidget)
      this.enableSaveButton();
  }

  /**
   * Add col at widget current.
   * @param indexResizeWidget The index widget selected.
   */
  widgetTextAdjustment(indexResizeWidget: number): void {
    /** Set in gridster properties to avoid overlapping widgets. */
    const gridsterItemSelected = this.gridItem.gridster.grid[indexResizeWidget];
    const itemResized = new GridsterPush(gridsterItemSelected);
    const elementGrid = this.configurableObject?.target as GridsterItemWidget;
    gridsterItemSelected.$item.cols = elementGrid.cols;
    gridsterItemSelected.$item.rows = elementGrid.rows;
    gridsterItemSelected.$item.minItemCols = elementGrid.minItemCols;
    gridsterItemSelected.$item.minItemRows = elementGrid.minItemRows;

    if (
      itemResized.pushItems(itemResized.fromEast) &&
      itemResized.pushItems(itemResized.fromNorth)
    ) {
      this.actionsForGridElements(gridsterItemSelected, itemResized);
    }
    itemResized.destroy();
    this.changedOptions();
  }

  /**
   * Get limit of page by rows of widget.
   * @param widget The widget for evaluate.
   * @returns The limit to list items on widget.
   */
  limitListPerPage(widget: GridsterItemWidget): number {
    return widget.rows * 2;
  }

  /**
   * Say to library tab recheck its questions.
   */
  checkLibraryTabQuestions(): void {
    this.checkLibraryTab.emit();
    this.getAllInputFrameWidgets.emit(this.inputFrameWidgetItems);
  }

  /**
   * Check bucket station questions.
   */
  checkBucketQuestions(): void {
    this.checkBucketTab.emit();
    this.getAllInputFrameWidgets.emit(this.inputFrameWidgetItems);
  }

  /** This cancel button clicked show alert. */
  async cancelStationChanges(): Promise<void> {
    const confirm = await this.popupService.confirm({
      title: 'Are you sure?',
      message: 'Changes that have not been saved will be lost.',
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      important: true,
    });
    if (confirm) {
      this.resetGridChanges();
    }
  }

  /** Should reset all the changes made within the grid. */
  resetGridChanges(): void {
    this.setEditMode(false);
    this.configurableObject = undefined;
    this.configurableData.emit(this.configurableObject);
    this.isEmptyPrompt = false;

    this.cancelInputFrameChanges();
    //Revert station widgets item to a copy of storedInputFrameWidgetItems.
    this.getAllInputFrameWidgets.emit(this.inputFrameWidgetItems);
    this.restoreBucketData.emit();
    this.checkLibraryTabQuestions();
    this.checkBucketQuestions();
    this.updateContainerLabel();
    this.deleteTempImages(false);
    if (this.invalidQuestion) {
      this.stationService.fieldWithInvalidOptions$.next({
        selectableQuestion: this.invalidQuestion.rithmId,
        isInvalid: false,
      });
    }
  }

  /**
   * A copy of the container label is obtained in case of cancelling station changes.
   */
  getContainerLabel(): void {
    this.stationService
      .getContainerLabel(this.stationRithmId)
      .pipe(first())
      .subscribe({
        next: (containerName) => {
          this.containerLabelDefault = containerName;
          this.containerService.containerLabelText$.next({
            rithmId: this.stationRithmId,
            value: this.containerLabelDefault,
          });
        },
        error: () => {
          this.popupService.notify(
            'Something has gone wrong, please try again.',
          );
        },
      });
  }

  /**
   * Set new container label.
   */
  updateContainerLabel(): void {
    const reqData: StandardStringJSON = {
      data: this.containerLabelDefault,
    };
    this.stationService
      .updateContainerLabel(this.stationRithmId, reqData)
      .pipe(first())
      .subscribe({
        next: (containerName) => {
          this.containerService.updatedContainerLabelText({
            rithmId: this.stationRithmId,
            value: containerName.data,
          });
        },
        error: () => {
          this.popupService.notify(
            'Something has gone wrong, please try again.',
          );
        },
      });
  }

  /**
   * Delete questions from a specific frame.
   * @param question The object that contains the question information.
   * @param containerIndex The index of the parent container of the question.
   */
  async removeQuestionFromFrame(
    question: Question,
    containerIndex: number,
  ): Promise<void> {
    if (containerIndex <= -1) return;
    // if frame exists and index is positive.
    const targetFrame = this.inputFrameWidgetItems[containerIndex];
    const questionIndex =
      targetFrame.questions?.findIndex(
        ({ rithmId }) => rithmId === question.rithmId,
      ) ?? -1;
    // This cancels the request.
    if (question.questionType === QuestionFieldType.SelfAssign) {
      this.saveAssignmentStatus = undefined;
    }
    if (questionIndex > -1) {
      targetFrame.questions?.splice(questionIndex, 1);

      this.stationService.recalculateDimensionsFrame({
        currentFrameRithmId: targetFrame.rithmId,
        action: ActionTypeFrameDimensionEvent.Remove,
      });

      this.configurableObject = undefined;
      this.configurableData.emit(this.configurableObject);
    }
    this.changedOptions();
    this.checkBucketQuestions();
    this.checkLibraryTabQuestions();
  }

  /**
   * Cancels local inputFrameWidgetItems changes.
   */
  async cancelInputFrameChanges(): Promise<void> {
    //Revert station widgets item to a copy of storedInputFrameWidgetItems and reset storedInputFrameWidgetItems.
    this.inputFrameWidgetItems = _.cloneDeep(
      this.stationService.storedInputFrameWidgetItems,
    );
    this.inputFrameList = _.cloneDeep(this.stationService.storedInputFrameList);
    this.inputFramesList.emit(this.inputFrameList);

    /** Place the initial name in the container label. */
    const frameQuestions = await InputFrame.extractFrameQuestions(
      this.inputFrameWidgetItems,
    );

    const containerNameQ = frameQuestions.find(
      (q) => q.questionType === QuestionFieldType.ContainerName,
    );
    if (containerNameQ) {
      this.containerService.updatedContainerLabelText({
        rithmId: this.stationRithmId,
        value: containerNameQ.prompt,
      });
      this.containerService.updateQuestionFieldValueById({
        rithmId: this.stationRithmId,
        value: containerNameQ.prompt,
      });
    }
  }

  /**
   * Validate if any question is missing prompt.
   */
  async checkQuestionsPrompt(): Promise<void> {
    const frameQuestions = await InputFrame.extractFrameQuestions(
      this.inputFrameWidgetItems,
    );

    this.isEmptyPrompt = frameQuestions.some(
      (que: Question) => !que.prompt.length,
    );
  }

  /**
   * Enabled/Disabled the save button if the customId settings are valid or not.
   */
  async enableSaveButton(): Promise<void> {
    let customFieldInvalid = false;
    let functionFieldInvalid = false;
    let invalidSelectableQ = false;
    let isRelationshipInvalid = false;

    const frameQuestions = await InputFrame.extractFrameQuestions(
      this.inputFrameWidgetItems,
    );

    if (frameQuestions.length) {
      /** If the added question is CustomId, we need to verify that its required fields are complete to enable the save button. */
      customFieldInvalid = frameQuestions.some(
        (question) =>
          question.questionType === QuestionFieldType.CustomId &&
          question.settings?.length &&
          this.inputFrameHelper.isCustomIdInvalid(question.settings),
      );

      /** If the added question is a function, we need to verify that its required fields are complete to enable the save button. */
      functionFieldInvalid = frameQuestions.some(
        (question) =>
          question.questionType === QuestionFieldType.Function &&
          (!question.settings?.length ||
            (question.settings?.length &&
              this.inputFrameHelper.isFunctionInvalid(question.settings))),
      );

      /** Extracts the selectable questions existing in the frames. */
      const selectableQuestions = frameQuestions.filter((question) =>
        this.questionTypeSelectable.includes(question.questionType),
      );

      /** If there are selectable questions, check that they have valid options. */
      if (selectableQuestions.length) {
        invalidSelectableQ =
          this.areInvalidSelectableQuestions(selectableQuestions);
      }
    }

    if (this.inputFrameWidgetItems.length) {
      isRelationshipInvalid = !!this.inputFrameWidgetItems.filter((widget) => {
        if (widget.widgetType === WidgetType.RelationshipWidget) {
          return !WidgetFiltersHelper.isValidRelationshipWidget(widget);
        }
        return false;
      }).length;
    }

    /**
     * Disables the button in the form if any of the following conditions are met:
     * 1. Incomplete customId or data and field in function.
     * 2. Duplicate choices in a selectable type question.
     * 3. Incomplete relationship data.
     */
    if (
      this.inputFrameWidgetItems.length &&
      (customFieldInvalid ||
        functionFieldInvalid ||
        invalidSelectableQ ||
        isRelationshipInvalid)
    ) {
      this.stationGridForm.controls.inputFrameFieldForm.setErrors({
        valid: false,
      });
    } else {
      if (
        !this.stationGridForm.controls.inputFrameFieldForm.valid &&
        !invalidSelectableQ
      ) {
        this.stationGridForm.controls.inputFrameFieldForm.reset();
        this.stationGridForm.controls.inputFrameFieldForm.setErrors(null);
      }
    }
  }

  /**
   * Check that the selectable questions are not the same or empty.
   * @param selectableQuestions Selectable questions extracted from the frames.
   * @returns A boolean.
   */
  areInvalidSelectableQuestions(selectableQuestions: Question[]): boolean {
    /** Check if there is an invalid selectable question in the grid. */
    const invalidFrameOptions = selectableQuestions.some(
      ({ possibleAnswers }) =>
        possibleAnswers?.some((answer, index, option) =>
          InputFrame.isSelectableFieldInvalid(answer.text, option),
        ),
    );
    if (this.configurableObject?.target) {
      const questionInProgress = this.configurableObject?.target as Question;

      /** Check if in the current question there are equal or empty choices. */
      const invalidOptions =
        questionInProgress.possibleAnswers?.some((answer, index, option) =>
          InputFrame.isSelectableFieldInvalid(answer.text, option),
        ) || false;
      /** This subject will be executed in case your choices are valid or not. */
      this.stationService.fieldWithInvalidOptions$.next({
        selectableQuestion: questionInProgress.rithmId,
        isInvalid: invalidOptions,
      });
    }
    if (invalidFrameOptions) {
      this.invalidQuestion = selectableQuestions.find(({ possibleAnswers }) =>
        possibleAnswers?.some((answer, index, option) =>
          InputFrame.isSelectableFieldInvalid(answer.text, option),
        ),
      ) as Question;
      if (this.invalidQuestion) {
        this.stationService.fieldWithInvalidOptions$.next({
          selectableQuestion: this.invalidQuestion.rithmId,
          isInvalid: true,
        });
      }
    }
    return invalidFrameOptions;
  }

  /**
   * Delete temp images.
   * @param isSave If save dashboard.
   */
  private deleteTempImages(isSave: boolean): void {
    const imagesRithmIdTemp: string[] = [];
    this.imagesRithmIdToDelete.map((image) => {
      if (isSave && !image.isNewImage) {
        imagesRithmIdTemp.push(image.rithmId);
      } else if (!isSave && image.isNewImage) {
        imagesRithmIdTemp.push(image.rithmId);
      }
    });
    imagesRithmIdTemp.length &&
      this.containerService
        .deleteImages(imagesRithmIdTemp)
        .pipe(first())
        .subscribe({
          next: () => (this.imagesRithmIdToDelete = []),
        });
    !imagesRithmIdTemp.length && (this.imagesRithmIdToDelete = []);
  }

  /**
   * Find frame rithmId by question.
   * @param questionRithmId Question to find.
   * @returns The frame index.
   */
  private findFrameIndexByQuestion(questionRithmId: string): number {
    return this.inputFrameWidgetItems.findIndex((frame) =>
      frame.questions?.some(({ rithmId }) => rithmId === questionRithmId),
    );
  }

  /**
   * Find frame rithmId by rithmId.
   * @param frameRithmId Frame rithmId.
   * @returns The frame index.
   */
  private findFrameIndex(frameRithmId: string): number {
    return this.inputFrameWidgetItems.findIndex(
      ({ rithmId }) => rithmId === frameRithmId,
    );
  }

  /**
   * Close tab setting.
   */
  closeTabSetting(): void {
    this.configurableData.emit(undefined);
  }

  /**
   * Whether the widget is a dashboard type.
   * @param type Widget type to check.
   * @returns Boolean.
   */
  isDashboardWidget(type: WidgetType): boolean {
    return DashboardWidgets.isDashboardWidget(type);
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    window.removeEventListener('beforeunload', this.prevent);
  }
}
