import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NgxPopperjsModule,
  NgxPopperjsPlacements,
  NgxPopperjsTriggers,
} from 'ngx-popperjs';
import { MatDialog } from '@angular/material/dialog';
import { Subject, first, map, takeUntil } from 'rxjs';
import { PopupService } from 'src/app/core/popup.service';
import {
  ActionType,
  ActionAlertInfo,
  PowerAction,
  UserAssociateType,
  MemberPermissionData,
  RecipientInfo,
  BaseNotification,
  NotificationsType,
} from 'src/models';
import { StationService } from 'src/app/core/station.service';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { NotificationTypeModalComponent } from 'src/app/station/rules/actions/notification-type-modal/notification-type-modal.component';
import { AdvancedSearchModalComponent } from 'src/app/search/advanced-search-modal/advanced-search-modal.component';
import { v4 as uuidv4 } from 'uuid';
import { UserService } from 'src/app/core/user.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ComponentHelper, JsonValidator } from 'src/helpers';
import { UserAvatarComponent } from 'src/app/shared/user-avatar/user-avatar.component';

/**
 * Component to add/edit/delete Push/Silent/Browser notification.
 */
@Component({
  selector: 'app-internal-notification-form[stationRithmId]',
  templateUrl: './internal-notification-form.component.html',
  styleUrls: ['./internal-notification-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgxPopperjsModule,
    MatChipsModule,
    UserAvatarComponent,
    MatAutocompleteModule,
    LoadingIndicatorComponent,
    NgxMatSelectSearchModule,
  ],
})
export class InternalNotificationFormComponent implements OnInit {
  /** Members search input. */
  @ViewChild('membersInput') membersInput!: ElementRef;

  /** Subject for whether the component was destroyed/unsubscribe. */
  private destroyed$ = new Subject<void>();

  /** Contains the action of the power that will be edited. */
  private _actionToUpdate!: PowerAction | null;

  /** The station id used to get shared values. */
  @Input() stationRithmId!: string;

  /** Contains the action of the power that will be edited. */
  @Input() set action(value: PowerAction | null) {
    this._actionToUpdate = value;
    this.setInformationToEdit();
  }

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /** Whether you are saving the current integration in parent. */
  @Input() savingNotification = false;

  /** Whether you are deleting the current integration in parent. */
  @Input() deletingNotification = false;

  /** Emit the new action to add. */
  @Output() notificationPowerEmitter = new EventEmitter<PowerAction>();

  /** Emit the new action to add. */
  @Output() cancelFormEmitter = new EventEmitter();

  /** Component Helper. */
  componentHelper = ComponentHelper;

  /** Emit the current notification to delete it. */
  @Output() notificationToRemove = new EventEmitter<PowerAction>();

  /** The notification updated data to save.*/
  notificationUpdate!: ActionAlertInfo;

  /** Associate types except user.*/
  readonly associateTypes = [
    UserAssociateType.Organization,
    UserAssociateType.Team,
    UserAssociateType.StationGroup,
    UserAssociateType.Station,
  ];

  /** Members for station or organization. */
  members: MemberPermissionData[] = [];

  /** Members selected in the chit-set list. */
  selectedMembers: MemberPermissionData[] = [];

  /** Enum NgxPopperjsPlacements for define orientation popover.*/
  enumPopperPlacement = NgxPopperjsPlacements;

  /** Enum NgxPopperjsTriggers for define event popover.*/
  enumPopperTrigger = NgxPopperjsTriggers;

  /** List of notification types selected. */
  selectedNotification: (BaseNotification | NotificationsType)[] = ['Push'];

  /** List of internal Notifications. */
  readonly notificationTypeList: (BaseNotification | NotificationsType)[] = [
    'Push',
    'Silent',
    'Browser',
  ];

  /** Notification action form. */
  notificationActionForm = new FormGroup({
    addMembers: new FormControl<string[]>([]),
    inputNote: new FormControl<string>('', {
      validators: [
        Validators.maxLength(280),
        Validators.minLength(5),
        Validators.required,
      ],
    }),
  });

  /** Control for the MatSelect filter keyword for option groups. */
  stationMembersFilter: FormControl = new FormControl();

  /** Make close button visible/hidden in the chip-set. */
  removable = true;

  /** Whether the request to get/update the notification is currently underway. */
  notificationLoading = false;

  //** Make add notification controls visible. */
  enableAddNotification = false;

  /** Error Get Members. */
  errorGetMembers = false;

  /** Enable Notification create section. */
  enableNotificationType = false;

  /** Add more notification. */
  enableAddMore = true;

  /** The Internal notification list.*/
  notificationsData = false;

  /** Current form is in edit mode or not. */
  editMode = false;

  constructor(
    private popupService: PopupService,
    private stationService: StationService,
    private dialog: MatDialog,
    private userService: UserService,
  ) {}

  /**
   * Returns whether the notification members selected.
   * @returns Boolean value.
   */
  get selectedMembersValid(): boolean {
    return this.selectedMembers.length > 0;
  }

  /**
   * Returns whether the notification type selected.
   * @returns Boolean value.
   */
  get noNotificationSelected(): boolean {
    return this.selectedNotification.length > 0;
  }

  /**
   * Set up FormBuilder group.
   */
  ngOnInit(): void {
    const validators: ValidatorFn[] = [];
    if (this.noNotificationSelected) {
      validators.push(Validators.required);
    }

    // listen for search field value changes
    this.stationMembersFilter.valueChanges
      .pipe(
        map((value) => value || ''),
        takeUntil(this.destroyed$),
      )
      .subscribe((selectedValue) => {
        const search = selectedValue.toLowerCase();
        this.getRecipientMemberList(search);
      });
  }

  /**
   * Check if the member is already selected.
   * @param memberId Member's rithmId.
   * @returns True if the member is already selected.
   */
  isMemberAlreadySelected(memberId: string): boolean {
    return this.selectedMembers.some(({ rithmId }) => rithmId === memberId);
  }

  /**
   * Get the list of members.
   * @param searchString Search string.
   */
  getRecipientMemberList(searchString = ''): void {
    this.notificationLoading = true;
    this.errorGetMembers = false;
    this.stationService
      .getStationMembers(this.stationRithmId, '', 1, 100, searchString)
      .pipe(first())
      .subscribe({
        next: (members) => {
          this.members = members;
          this.notificationLoading = false;
        },
        error: () => {
          this.errorGetMembers = true;
          this.notificationLoading = false;
        },
      });
  }

  /**
   * Add selected recipients from the list.
   * @param recipients Recipients selected.
   */
  addRecipient(recipients: string[]): void {
    this.notificationActionForm.controls.addMembers.setValue([]);
    recipients.forEach((recipientId) => {
      const isRecipientAlreadySelected =
        this.isMemberAlreadySelected(recipientId);

      if (!isRecipientAlreadySelected) {
        const memberSelected = this.members.find(
          ({ rithmId }) => rithmId === recipientId,
        );
        if (memberSelected) {
          this.selectedMembers.push(memberSelected);
        }
      }
    });
    this.notificationActionForm.controls.addMembers.setValue(recipients);
  }

  /**
   * Remove selected member from the chip-set.
   * @param selectedRithmID Selected rithmID.
   */
  removeRecipient(selectedRithmID: string): void {
    const index = this.selectedMembers.findIndex(
      (member) => member.rithmId === selectedRithmID,
    );
    if (index >= 0) {
      this.selectedMembers.splice(index, 1);
      this.notificationActionForm.controls.addMembers.setValue([]);
      const membersSelected: string[] = [];
      this.selectedMembers.forEach(({ rithmId }) => {
        membersSelected.push(rithmId);
      });
      this.notificationActionForm.controls.addMembers.setValue(membersSelected);
    }
  }

  /**
   * Saving action type changes.
   *
   */
  public addNotificationAction(): void {
    const recipientsInfo: RecipientInfo = {
      organizations: this.selectedMembers.filter(
        (rec) => rec.type === UserAssociateType.Organization,
      ),
      teams: this.selectedMembers.filter(
        (rec) => rec.type === UserAssociateType.Team,
      ),
      stationGroups: this.selectedMembers.filter(
        (rec) => rec.type === UserAssociateType.StationGroup,
      ),
      stations: this.selectedMembers.filter(
        (rec) => rec.type === UserAssociateType.Station,
      ),
      users: this.selectedMembers.filter(
        (rec) => rec.type === UserAssociateType.User,
      ),
    };
    const actionAlertInfo: ActionAlertInfo = {
      createdByRithmId: this.userService.user.rithmId,
      message: this.notificationActionForm.controls.inputNote.value || '',
      to: [],
      types: this.selectedNotification,
      recipients: recipientsInfo,
    };
    const createdOrUpdatedAction: PowerAction = {
      order: this._actionToUpdate ? this._actionToUpdate.order : 0,
      rithmId: this._actionToUpdate ? this._actionToUpdate.rithmId : uuidv4(),
      type: ActionType.AlertMessage,
      target: this.stationRithmId,
      data: JSON.stringify(actionAlertInfo),
      resultMapping: '',
      header: '',
    };
    this.notificationPowerEmitter.emit(createdOrUpdatedAction);
  }

  /**
   * This method will set the information to edit an already created action.
   */
  setInformationToEdit(): void {
    this.getRecipientMemberList();
    if (this._actionToUpdate && this._actionToUpdate.data) {
      const actionData = this._actionToUpdate.data.replace(/'/g, ' ');
      const dataParse =
        JsonValidator.getObjectFromString<ActionAlertInfo>(actionData);
      this.selectedNotification = dataParse.types;
      const recipients = dataParse.recipients;
      this.selectedMembers = [
        ...recipients.organizations,
        ...recipients.teams,
        ...recipients.stationGroups,
        ...recipients.stations,
        ...recipients.users,
      ];
      this.notificationActionForm.controls.addMembers.setValue(
        this.selectedMembers.map((data) => {
          return data.rithmId;
        }),
      );
      this.notificationActionForm.controls.inputNote.setValue(
        dataParse.message,
      );
      this.editMode = true;
    }
  }

  /**
   * Open notification type info modal.
   */
  openNotificationTypeInfoModal(): void {
    this.dialog.open(NotificationTypeModalComponent, {
      disableClose: true,
      panelClass: ['w-2/4', 'sm:w-5/5'],
      minHeight: '450px',
    });
  }

  /**
   * Toggle the Notification type selected.
   * @param typeSelected Notification type selected.
   */
  changeTypeSelected(typeSelected: BaseNotification | NotificationsType): void {
    if (this.selectedNotification.includes(typeSelected)) {
      const notificationIndex = this.selectedNotification.indexOf(typeSelected);
      this.selectedNotification.splice(notificationIndex, 1);
    } else {
      this.selectedNotification.push(typeSelected);
    }
  }

  /**
   * Check if notification type is selected or not.
   * @param type Type of the notification.
   * @returns True if selected else false.
   */
  checkTypeSelected(type: BaseNotification | NotificationsType): boolean {
    return this.selectedNotification.includes(type);
  }

  /**
   * Open advanced search modal.
   */
  openAdvancedSearchModal(): void {
    const dialog = this.dialog.open(AdvancedSearchModalComponent, {
      disableClose: true,
      panelClass: ['w-11/12', 'md:w-4/6', 'lg:w-2/4'],
      maxWidth: '600px',
      autoFocus: false,
    });
    dialog
      .afterClosed()
      .pipe(first())
      .subscribe((membersList: MemberPermissionData[]) => {
        if (membersList && membersList.length) {
          membersList.map((member) => {
            const index = this.selectedMembers.findIndex(
              (mem) => mem.rithmId === member.rithmId,
            );
            if (index === -1) {
              this.selectedMembers.push(member);
            }
          });
        }
      });
  }

  /**
   * Initiate a confirmation popup for cancel changes in editMode.
   */
  async confirmCancel(): Promise<void> {
    const response = await this.popupService.confirm({
      title: 'Cancel?',
      message: 'Any unsaved progress will be lost',
      okButtonText: 'Yes, Cancel',
      cancelButtonText: 'Back',
      important: true,
    });
    if (response) {
      this.notificationsData = false;
      this.resetNotificationActionSection();
      this.cancelFormEmitter.emit();
    }
  }

  /**
   * Check if a recipient is a user.
   * @param value Recipient.
   * @returns Whether the recipient is a user.
   */
  isUserType(value: MemberPermissionData): boolean {
    return value.type === UserAssociateType.User;
  }

  /**
   * Reset the changes done across the notification form.
   */
  resetNotificationActionSection(): void {
    this.enableAddMore = true;
    this.enableAddNotification = false;
    this.enableNotificationType = false;
    this.selectedMembers = [];
    this.notificationActionForm.controls.inputNote.setValue('');
  }

  /**
   * Remove the notification from the parent.
   */
  removeParentNotification(): void {
    this._actionToUpdate &&
      this.notificationToRemove.emit(this._actionToUpdate);
  }
}
